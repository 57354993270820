import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { WSS_EVENT } from '../app.constants';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: any;

  constructor() {
    this.socket = io(environment.wsServer);
  }

  sendMessage(message: any) {
    this.socket.emit(WSS_EVENT, message);
  }

  onMessageReceived(callback: Function) {
    this.socket.on(WSS_EVENT, (data: any) => {
      callback(data);
    });
  }
}
