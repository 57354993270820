import { Injectable, numberAttribute } from '@angular/core';
import { Team } from '../pages/models/team';
import { SESSION_QUESTION_INDEX, SESSION_QUESTIONS, SESSION_SELECTED_TEAM } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';
import { Question } from '../pages/models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {


  currentQuestionIndex: number = -1;
  questions!: Question[];

  constructor(private http: HttpClient) {
  }

  /**
   * Get the teams from app server
   * @returns 
   */
  initQuestions(): void {
    this.http.get<any>(environment.appServer + '/questions/get').subscribe((questions: any) => {
      this.questions = questions;
      var qData = JSON.stringify(questions);
      sessionStorage.setItem(SESSION_QUESTIONS, qData)
      sessionStorage.setItem(SESSION_QUESTIONS, this.currentQuestionIndex.toString())
    });
  }

  /**
   * 
   * @returns 
   */
  getQuestions(): Question[] {
    var qData = sessionStorage.getItem(SESSION_QUESTIONS);
    var questions = (qData !== null) ? JSON.parse(qData) : [];
    return questions;
  }
  /**
   * 
   */
  getNextQuestion(): any {
    this.currentQuestionIndex = Number(sessionStorage.getItem(SESSION_QUESTION_INDEX));
    this.currentQuestionIndex++;
    var question = undefined;
    if (this.currentQuestionIndex < this.questions.length) {
      question = this.questions[this.currentQuestionIndex];
    }
    return question;
  }
  /**
   * 
   * @returns 
   */
  getCurrentQuestionIndex(): number {
    return this.currentQuestionIndex;
  }
  /**
   * 
   */
  isFinished(): boolean {
    return (this.currentQuestionIndex >= this.questions.length);
  }
}
