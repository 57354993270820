<div class="score-edit-root-container">
    <div *ngIf="!started" class="team-selector">
        <span class="team-prompt">Please select the team</span>
        <div class="team-list">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedTeam" (selectionChange)="onTeamChange(selectedTeam)">
                    <mat-option *ngFor="let team of teams" [value]="team">
                        {{ team.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="team-info">
            <span *ngIf="selectedTeam">Score:{{selectedTeam.score}}</span>
        </div>
        <div style="flex: 1;"></div>
        <div *ngIf="selectedTeam && selectedTeam.score>0" class="action-div">
            <button color="warn" mat-flat-button (click)="reset()">Reset</button>
            <button mat-flat-button (click)="go()">Go</button>
        </div>
        <div *ngIf="selectedTeam &&  selectedTeam.score==0">
            <button style="min-width: 150px;"  mat-flat-button (click)="go()">Go</button>
        </div>
    </div>
    <div *ngIf="started" class="team-score-container">
        <span class="title" [ngStyle]="teamStyle">{{selectedTeam.title}}</span>
        <div class="score-div">
            <div class="score-content">
                <span class="score">{{selectedTeam.score}}</span>
                <span class="score-title">Score</span>
            </div>
        </div>
        <div *ngIf="scoreData!==undefined && scoreData.length>0" style="display:flex;flex-direction:column;flex:1">
            <div class="score-list">
                @for(score of this.scoreData;track score.id){
                <button mat-button class="score" [ngStyle]="getButtonColor(score)" (click)="editScore(score)">
                    {{score.code}}
                </button>
                }
            </div>
        </div>
        <div style="flex: 1;"></div>
        <div *ngIf="started" class="action-div">
            <button class="finidh-btn" mat-flat-button (click)="finish()">
                Finish
            </button>
        </div>
    </div>
</div>