import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Team } from '../../../models/team';


@Component({
  selector: 'app-score-board-card',
  standalone: true,
  imports: [ CommonModule],
  templateUrl: './score-board-card.component.html',
  styleUrl: './score-board-card.component.scss'
})
export class ScoreBoardCardComponent implements OnChanges {


  @Input()team!:Team;

  teamStyle:any={
    backgroundColor:"blue",
    color:"white"
  };
  
  constructor(){}

  ngOnChanges(changes: SimpleChanges): void {
   
    if(changes['team']){ 
      this.teamStyle={
        backgroundColor:this.team.bgcolor,
         color:this.team.fgcolor,
      }
    }

  }

  


}
