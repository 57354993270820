import { Component } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SocketService } from '../../services/socket.service';
import { TeamService } from '../../services/team.service';
import { Team } from '../models/team';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm-dialog.component';
import { ScoreBoardEvent } from '../models/score-event';
import { EVENT_SCORE_UPDATED } from '../../app.constants';

@Component({
  selector: 'app-score-edit',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCommonModule, MatSelectModule, MatButtonModule, MatDialogModule],
  templateUrl: './score-edit.component.html',
  styleUrl: './score-edit.component.scss'
})
export class ScoreEditComponent {

  score: [] = [];
  newScore: any[] = [];

  teams!: Team[];
  selectedTeam: any;
  scoreData: any = [];
  teamStyle: any;
  started: boolean = false;

  constructor(private teamService: TeamService,
    private router: Router,
    private dialog: MatDialog,
    private socketService: SocketService,
  ) {

    document.title = "Quiz: Edit Score";

    this.teamService.getTeams()
      .subscribe(teams => {
        this.teams = teams;
      });

  }
  /**
   * 
   * @param team 
   */
  onTeamChange(team: Team) {


    this.teamStyle = {
      backgroundColor: team.bgcolor,
      color: team.fgcolor,
    }
  }
  /**
   * 
   * @param score 
   * @returns 
   */
  getButtonColor(score: any) {
    var buttonColor = { backgroundColor: 'gray' };
    switch (score.score) {
      case 0:
        buttonColor = { backgroundColor: 'red' };
        break;
      case 1:
        buttonColor = { backgroundColor: '#6bf73a' };
        break;
    }
    return buttonColor;
  }
  /**
   * 
   * @param score 
   */
  editScore(score: any): void {
    if (score.score == null) {
      this.addScore(score);
    } else {
      this.updateScore(score);
    }
  }
  /**
   * 
   * @param score 
   */
  addScore(score: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',

      data: {
        title: 'Confirmation!',
        message: `${score.code}?`,
        yesLabel: "CORRECT",
        noLabel: "WRONG",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const scoreValue = (result) ? 1 : 0;
        this.selectedTeam.score += scoreValue;
        score.score = scoreValue;
        var event: ScoreBoardEvent = { event_type: EVENT_SCORE_UPDATED, data: { id: this.selectedTeam.id, score: this.selectedTeam.score } };
        this.socketService.sendMessage(event);
        var scoreData = { team_id: this.selectedTeam.id, question_id: score.question_id, score: scoreValue };
        this.teamService.saveScore(scoreData).subscribe((respose: any) => {
          this.teamService.getScore(this.selectedTeam.id).subscribe((score: any) => {
            this.scoreData = score;
          });
        });

      } else {

      }
    });
  }
  /**
   * 
   * @param score 
   */
  updateScore(score: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirmation!',
        message: `${score.code}`,
        yesLabel: "CORRECT",
        noLabel: "WRONG"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        score.score = (result) ? 1 : 0;
        this.teamService.updateScore(score).subscribe((respose: any) => {
          var teamData = respose;
          this.selectedTeam.score = teamData.score;
          var event: ScoreBoardEvent = { event_type: EVENT_SCORE_UPDATED, data: { id: teamData.id, score: teamData.score } };
          this.socketService.sendMessage(event);
        })
      } else {
        console.log('Dismissed');
      }
    });
  }

  finish() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirmation!',
        message: `Are you sure do you want to exit`,
        yesLabel: "Yes",
        noLabel: "No"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.started = false;
      } else {
        console.log('Dismissed');
      }
    });
  }
  /**
   * 
   */
  go(): void {
    this.teamService.getScore(this.selectedTeam.id).subscribe((score: any) => {
      this.scoreData = score;
      this.started = true;
    });
   
  }
  /**
   * 
   */
  reset(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirmation!',
        message: 'This action will clear your current score and restart the QUIZ. Are you sure you want to proceed?',
        yesLabel: "Yes",
        noLabel: "No"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedTeam.score = 0;
        this.selectedTeam.last_question = 0;
        this.teamService.resetScore(this.selectedTeam.id);
        var event: ScoreBoardEvent = { event_type: EVENT_SCORE_UPDATED, data: { id: this.selectedTeam.id, score: this.selectedTeam.score } };
        this.socketService.sendMessage(event);
      } else {
        console.log('Dismissed');
      }
    });

  }
}
