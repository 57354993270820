import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { TeamService } from '../../services/team.service';
import { SocketService } from '../../services/socket.service';
import { Team } from '../models/team';
import { FormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { QuestionService } from '../../services/question.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { EVENT_SCORE_UPDATED, SESSION_QUESTION_INDEX } from '../../app.constants';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm-dialog.component';
import { ScoreBoardEvent } from '../models/score-event';



@Component({
  selector: 'app-quiz',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCommonModule, MatSelectModule, MatButtonModule, MatDialogModule],
  templateUrl: './quiz.component.html',
  styleUrl: './quiz.component.scss',
})
export class QuizComponent {

  teams!: Team[];
  selectedTeam!: Team;

  constructor(private teamService: TeamService,
    private questionService: QuestionService,
    private router: Router,
    private dialog: MatDialog,
    private socketService:SocketService
  ) {

    document.title = "Quiz: Welome";
    this.questionService.initQuestions();

    this.teamService.getTeams()
      .subscribe(teams => {
        this.teams = teams;
      });
  }

  ngOnInit() {


  }

  continue(restart: any = 0) {
    if (restart) {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '250px', // Optional: Set dialog width
        data: {
          title: 'Confirmation!',
          message: 'This action will clear your current score and restart the QUIZ. Are you sure you want to proceed?',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedTeam.score = 0;
          this.selectedTeam.last_question=0;
          this.teamService.resetScore(this.selectedTeam.id);
          var event: ScoreBoardEvent = { event_type: EVENT_SCORE_UPDATED, data: { id: this.selectedTeam.id, score: this.selectedTeam.score } };
          this.socketService.sendMessage(event);
          this.setSelectedTeam();
        } else {
          console.log('Dismissed');
        }
      });
    } else {
     this.setSelectedTeam();
    }
  }

  /**
   * 
   */
  setSelectedTeam():void{
    sessionStorage.setItem(SESSION_QUESTION_INDEX,(this.selectedTeam.last_question-1).toString());
    this.teamService.setTeam(this.selectedTeam);
    this.router.navigate(['/qa'])
  }

}
