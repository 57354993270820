<div class="qa-root-container">
    <span class="title" [ngStyle]="teamStyle">{{team.title}}</span>
    <div class="content-div">
        <div class="score-div">
            <div class="score-content">
                <span class="score">{{team.score}}</span>
                <span class="score-title">Score</span>
            </div>
        </div>
        @if(!isQuizFinished()){
        <div class="question-opt-div">
            <div class="question-div" [style.border-color]="randomBGColor.backgroundColor">
                <span class="sn" [ngStyle]="randomBGColor">{{question?.sn}}</span>
                <span class="question">{{question?.description}}</span>
            </div>
            <div class="options-div">
                @for(opt of question.options;track opt.id){
                <div class="option">
                    {{opt.option}}
                </div>
                }
            </div>
        </div>
        <div class="filler-div"></div>
        <div class="action">
            <button class="wrong" mat-icon-button color="warn" (click)="continue(0)">
                <mat-icon>close</mat-icon>
            </button>
            <button class="correct" mat-icon-button (click)="continue(question.score)">
                <mat-icon>check</mat-icon>
            </button>
        </div>
        }
        <div *ngIf="isQuizFinished()" style="display:flex;flex-direction:column;flex:1">
            <div class="score-list">
                @for(score of this.scoreData;track score.id){
                <div class="score" [ngStyle]="{'background-color': score.score ? '#6bf73a' : 'red'}">
                    {{score.code}}
                </div>
                }
            </div>
            <div class="filler-div"></div>
            <div class="action"></div>
            <button class="finidh-btn" mat-flat-button (click)="finish()">
                Finish
            </button>
        </div>
    </div>
</div>