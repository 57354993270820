import { Component } from '@angular/core';
import { TeamService } from '../../services/team.service';
import { Team } from '../models/team';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { SocketService } from '../../services/socket.service';
import { ScoreBoardEvent } from '../models/score-event';
import { EVENT_SCORE_UPDATED } from '../../app.constants';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm-dialog.component';
import { QuestionService } from '../../services/question.service';
import { Question } from '../models/question';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';


@Component({
  selector: 'app-qa',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCommonModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './qa.component.html',
  styleUrl: './qa.component.scss'
})
export class QaComponent {

  color=['red','orange','yellow','blue','cyan','green','pink'];
  qcolor='red';
  team!: Team;
  // questions!: Question[];
  // currentQuestionIndex: number = 0;
  question: any;

  teamStyle: any = {
    backgroundColor: "blue",
    color: "white"
  };

  randomBGColor:any={ backgroundColor: "red"}
  prevRandomBGColor:any={ backgroundColor: "red"}
  scoreData: any;

  constructor(private teamService: TeamService,
    private socketService: SocketService,
    private questionSerivce: QuestionService,
    private router: Router,
    private dialog: MatDialog) {

    this.team = this.teamService.getTeam();
    this.question = this.questionSerivce.getNextQuestion();
    this.teamStyle = {
      backgroundColor: this.team.bgcolor,
      color: this.team.fgcolor,
    }
    this.loadScoreData();
    document.title = "Quiz: Q&A";
  }

  ngOnInt() {

  }

  /**
   * 
   * @param score 
   */
  continue(score: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px', 
      data: {
        title: 'Confirmation!',
        message: `Mark the answer as ${score ? 'CORRECT' : 'WRONG'}?`,
        yesLabel: "YES",
        noLabel: "NO"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const currentQuestionIndex = this.questionSerivce.getCurrentQuestionIndex();
        this.teamService.addScore(this.question.id, currentQuestionIndex, score);
        var event: ScoreBoardEvent = { event_type: EVENT_SCORE_UPDATED, data: { id: this.team.id, score: this.team.score } };
        this.socketService.sendMessage(event);
        this.question = this.questionSerivce.getNextQuestion();
        this.setRandomColor();
        if (this.isQuizFinished()) {
          this.loadScoreData();
        }
      } else {
        console.log('Dismissed');
      }
    });

  }
  /**
   * 
   * @returns 
   */
  isQuizFinished(): boolean {
    return this.questionSerivce.isFinished();
  }

  /**
   * Random color generator
   * @param min 
   * @param max 
   * @returns 
   */
  
   setRandomColor(): void {
    const min=1;
    const max=this.color.length;
    var newColor=this.randomBGColor;
    while (newColor.backgroundColor==this.prevRandomBGColor.backgroundColor) {
      const colorIndex= Math.floor(Math.random() * (max - min + 1)) + min;
      newColor={backgroundColor:this.color[colorIndex-1]};
    }
    this.prevRandomBGColor=newColor;
    this.randomBGColor=newColor;

  }
  /**
   * 
   */
  loadScoreData() {
    this.teamService.getScore(this.team.id).subscribe((score: any) => {
      this.scoreData = score;
    });
  }

  finish(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirmation!',
        message: `Are you sure do you want to end the QUIZ`,
        yesLabel: "Yes",
        noLabel: "No"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(["/welcome"])
      } else {
        console.log('Dismissed');
      }
    });
  }
}
