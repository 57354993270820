import { Routes } from '@angular/router';
import { ScoreBoardComponent } from './pages/score-board/score-board.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { QaComponent } from './pages/qa/qa.component';
import { ScoreEditComponent } from './pages/score-edit/score-edit.component';

export const routes: Routes = [
    {path:"score",component:ScoreBoardComponent},
    {path:"",component:QuizComponent},
    {path:"welcome",component:QuizComponent},
    {path:"qa",component:QaComponent},
    {path:"edit-score",component:ScoreEditComponent}

];
