export const environment = {

    appServer:"https://quiz.mojosoft.in/api",
    wsServer:"https://mojosoft.in:4001"
};


// export const environment = {
//     production:false,
//     appServer:"http://192.168.1.103",
//     wsServer:"https://mojosoft.in:4001"
// };


    // appServer:"http://192.168.1.103",
    // wsServer:"http://192.168.1.103:4001"
