import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScoreBoardService } from './score-board.service';
import { ScoreBoardCardComponent } from './componenet/score-board-card/score-board-card.component';
import { EVENT_SCORE_UPDATED } from '../../app.constants';
import { SocketService } from '../../services/socket.service';
import { TeamService } from '../../services/team.service';
import { ScoreBoardEvent } from '../models/score-event';
import { Team } from '../models/team';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-score-board',
  standalone: true,
  imports: [CommonModule,ScoreBoardCardComponent],
  templateUrl: './score-board.component.html',
  styleUrl: './score-board.component.scss',
  
})
export class ScoreBoardComponent {

  teams: any = [];

  constructor(private socketService:SocketService,
    private scoreBoardService:ScoreBoardService,
    private teamService:TeamService){

      document.title = "QUIZ: Score Board"
   }


  ngOnInit(){
    this.teamService.getTeams()
    .subscribe(teams => {
      this.teams = teams;
    });
    this.socketService.onMessageReceived((event:ScoreBoardEvent) => {
      console.log("message received");
      switch (event.event_type) {
        case EVENT_SCORE_UPDATED:
          this.updateScore(event.data);
          break;
      
        default:
          break;
      }
    });
  }
  /**
   * 
   * @param data 
   */
  updateScore(data: {id:number,score:number}) {

    var team:Team=this.teams.find((t:Team)=>t.id==data.id);
    if(team!==undefined){
      team.score=data.score;
    }
    
  }
  /**
   * 
   */
  sendMessage():void{
    this.socketService.sendMessage({event_type:EVENT_SCORE_UPDATED,data:{id:5,score:55}})
  }

}
