<div class="score-board-root-container">
   <div class="title-div" [ngStyle]="teamStyle">
      {{team.title}}
   </div>
   <div class="score-div">
      <div class="score">
         {{team.score}}
      </div>
      
   </div>
</div>