import { Injectable } from '@angular/core';
import { Team } from '../pages/models/team';
import { SESSION_QUESTION_INDEX, SESSION_SELECTED_TEAM } from '../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class TeamService {


  selectedTeam!: Team;

  constructor(private http: HttpClient) { }


  /**
   * Get the teams from app server
   * @returns 
   */
  getTeams(): Observable<any> {
    return this.http.get<any>(environment.appServer + '/teams/get');
  }
  /**
   * update the score
   * @param data 
   * @returns 
   */
  saveScore(data: any): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.appServer + '/score/add', data);
  }

  updateScore(data: any): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.appServer + '/score/update', data);
  }

  /**
   * Get the score
   */
  getScore(teamId: any): Observable<any> {
    var data = { id: teamId };
    return this.http.post(environment.appServer + '/score/getscore', data);
  }
  /**
   * Reset the scor
   * @param id 
   */
  resetScore(id: number) {
    var data = { id: id };
    this.http.post(environment.appServer + '/score/reset', data).subscribe((respose: any) => { });
  }

  /**
   * 
   * @param selectedTeam 
   */
  setTeam(selectedTeam: Team) {
    this.selectedTeam = selectedTeam;
    this.updateTeam(this.selectedTeam);
  }

  getTeam(): Team {
    var teamData = sessionStorage.getItem(SESSION_SELECTED_TEAM);
    if (teamData !== null) {
      this.selectedTeam = JSON.parse(teamData);
    }
    return this.selectedTeam;
  }
  /**
   * 
   * @param questionId 
   * @param questionIndex 
   * @param score 
   */
  addScore(questionId: number, questionIndex: number, score: number) {
    this.selectedTeam.score += score;
    var scoreData = { team_id: this.selectedTeam.id, question_id: questionId, score: score };
    this.saveScore(scoreData);
    this.updateTeam(this.selectedTeam);
    sessionStorage.setItem(SESSION_QUESTION_INDEX, questionIndex.toString());
  }

  updateTeam(selectedTeam: Team) {
    sessionStorage.setItem(SESSION_SELECTED_TEAM, JSON.stringify(selectedTeam));
  }
}
