<div class="quiz-root-container">
    <span class="title">Welcome to QUIZ</span>
    <span class="team-prompt">Please select the team</span>
    <div class="team-list">
        <mat-form-field >
            <mat-select [(ngModel)]="selectedTeam">
                <mat-option *ngFor="let team of teams" [value]="team">
                    {{ team.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="team-info">
        <span *ngIf="selectedTeam">Score:{{selectedTeam.score}}</span>
    </div>
  <div style="flex: 1;"></div>
    <div *ngIf="selectedTeam && selectedTeam.score>0" class="action-div">
        <button [disabled]="!selectedTeam" color="warn" mat-flat-button (click)="continue(1)">Restart</button>
        <button [disabled]="!selectedTeam" mat-flat-button (click)="continue()">Continue</button>
    </div>
    <div *ngIf="selectedTeam &&  selectedTeam.score==0">
        <button [disabled]="!selectedTeam" mat-flat-button (click)="continue()">Start</button>
    </div>
    
</div>